import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import ReturnLP_Hero_DESK_05052022 from "./../../../assets/ReturnLP_Hero_DESK_05052022.webp";
import ReturnHP_Hero_Mobile_New from "./../../../assets/ReturnHP_Hero_Mobile_New.webp";

const Hero: FC = () => {
  return (
    <div className="relative h-fit">
      {/* <div className="absolute inset-x-0 bottom-0 bg-gray-100" /> */}
      <div className="w-full h-fit">
        <div className="relative  h-fit  ">
          <div className="block h-fit">
            {/* <img
              src={ReturnLP_Hero_DESK_05052022}
              className="h-full w-full object-cover"
              alt="People working on laptops"
            /> */}
            <div className="hidden phoneFin:block h-full">
              <Image
                src={ReturnLP_Hero_DESK_05052022}
                alt="people"
                className="h-full w-full object-cover"
                priority
              />
            </div>
            <div className="block phoneFin:hidden h-fit">
              <Image
                src={ReturnHP_Hero_Mobile_New}
                alt="people"
                className="h-full w-full object-cover"
                priority
              />
            </div>
          </div>
          <div
            className="absolute top-0 bottom-0 left-0 right-0 z-10
            justify-center  phoneFin:text-center  text-center phoneFin:ml-0
    flex flex-col
           phoneFin:px-4 phoneFin:py-16 lg:py-32 lg:px-8 gap-y-4"
          >
            <h1 className=" leading-snug text-4xl font-medium tracking-normal phoneFin:text-5xl tablet:text-6xl   ">
              <span className=" text-white leading-snug  ">Özel Günlere</span>
              <br />
              <span className="text-white leading-snug ">Hazırsın</span>
            </h1>

            <span
              className="text-base text-white text-center w-full tablet:text-xl tablet:font-normal
           sm:max-w-3xl"
            >
              {/* Arzu ettiğin her şey artık senin, senin kararın. */}
              Sen sadece ne istediğine kadar ver gerisini
              <br />
              Vestiyer halletsin.
              <br />
            </span>
            <div className="">
              <span
                className="text-base text-white  max-w-lg text-left tablet:text-xl
           sm:max-w-3xl"
              >
                <b>Hemen Dene!</b>
              </span>
            </div>

            <div className="self-center top">
              <div className="space-y-4 sm:space-y-0  sm:inline-grid sm:grid-cols-2 sm:gap-5">
                <Link href={"/collections/collection"}>
                  <a className="flex items-center justify-center px-10 py-3 
                border border-transparent text-base font-semibold opacity-90  shadow-sm backdrop-blur-xl
                text-black bg-white hover:opacity-80 
                tablet:text-xl tablet:mt-4
                
                ">
                    Şimdi Keşfet
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
