import { ApolloClient } from "@apollo/client";
import Layout from "@components/common/Layout";
import ProductCarousel from "@components/common/ProductCarousel";

import { EventType, Hero } from "@components/home";
import CTAHero from "@components/home/CTAHero";
import { Divider } from "@components/ui";
import { initializeApollo } from "@lib/apollo";
import { getProductsQuery, getShopEventTypeQuery } from "graphql/queries";
import {
  getProducts,
  getProductsVariables,
} from "graphql/__generated__/getProducts";
import { Sorting } from "graphql/__generated__/globalTypes";
import { shopEventTypeQuery } from "graphql/__generated__/shopEventTypeQuery";

import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
  NextPage,
} from "next";
import Link from "next/link";

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient: ApolloClient<object> = initializeApollo();

  // const { data: eventTypeQuery } = await apolloClient.query<shopEventTypeQuery>(
  //   {
  //     query: getShopEventTypeQuery,
  //   }
  // );

  const { data: productData, error } = await apolloClient.query<
    getProducts,
    getProductsVariables
  >({
    query: getProductsQuery,
    variables: {
      input: {
        category: "elbise",
        collections: ["home-page"],
        eventTypes: null,
        colors: null,
        sizes: null,
        filters: null,
        dates: null,
        sorting: Sorting[Sorting.Recommended],
        offset: 1,
        limit: 8,
      },
    },
  });
  const { data: productDataCollection, error: errorCollection } = await apolloClient.query<
    getProducts,
    getProductsVariables
  >({
    query: getProductsQuery,
    variables: {
      input: {
        category: "elbise",
        collections: ["collection"],
        eventTypes: null,
        colors: null,
        sizes: null,
        filters: null,
        dates: null,
        sorting: Sorting[Sorting.Recommended],
        offset: 1,
        limit: 8,
      },
    },
  });

  return {
    props: {

      productData,
      productDataCollection
    },
  };
};

export default function Home({
  productData,
  productDataCollection
}: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <div className="flex flex-col">
      <Hero />
      <EventType />
      <Divider />
      {/* <ProductCarousel
        CollectionName="collection"
        HeadlineText="Vestiyer2022 Yaz Koleksiyonu"
        productData={productData}
      /> */}
      <ProductCarousel
        CollectionName="collection"
        HeadlineText="Zamansız parçalar"
        productData={productDataCollection}
      />
      <CTAHero />
      <ProductCarousel
        CollectionName="collection"
        HeadlineText="Zamansız parçalar"
        productData={productDataCollection}
      />
    </div>
  );
}

Home.Layout = Layout;
