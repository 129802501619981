import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import Return_HP_MidPage_Desktop_05052022 from "@assets/Return_HP_MidPage_Desktop_05052022.webp";
import Return_HP_MidPage_Mobile_05052022 from "@assets/Return_HP_MidPage_Mobile_05052022.webp";

const CTAHero: FC = () => {
  return (
    <Link href="/collections/collection">
      <a className="block w-full relative h-fit">
        <div className="-mb-1.5 hidden phoneFin:block">
          <Image
            src={Return_HP_MidPage_Desktop_05052022}
            alt="people"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="-mb-1.5 block phoneFin:hidden">
          <Image
            src={Return_HP_MidPage_Mobile_05052022}
            alt="people"
            className="h-full w-full object-cover"
          />
        </div>
        <div
          className="CTA absolute top-1/2 left-1/2  transform -translate-x-1/2
    //        -translate-y-1/2 flex  flex-col text-center "
        >
          <div className=" mb-4">
            <h1 className="text-4xl font-semibold text-white">
              En Gününde, En Özel Parçalar
            </h1>
          </div>
          <span className="text-base font-semibold underline text-white ">
            Hemen düğün için kirala
          </span>
        </div>
      </a>
    </Link>
  );
};

export default CTAHero;
