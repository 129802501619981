import { useQuery, gql, ApolloClient } from "@apollo/client";

import { initializeApollo } from "@lib/apollo";
import { getShopEventTypeQuery } from "graphql/queries";
import { shopEventTypeQuery } from "graphql/__generated__/shopEventTypeQuery";

import { GetStaticProps, InferGetStaticPropsType } from "next";
import Image from "next/image";
import Link from "next/link";

import { FC } from "react";

interface IEventType {
  eventTypeQuery: shopEventTypeQuery;
}

const eventTypeQuery = [
  {
    "__typename": "EventType",
    "id": "9abecdd9-f1a7-4848-908a-29678b9cba90",
    "name": "Düğün",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/2021111_HPReturn_Icon3_Weddings.png",
    "slug": "dugun"
  },
  // {
  //   "__typename": "EventType",
  //   "id": "0b170a05-22dd-4ef6-af26-44b03a60be28",
  //   "name": "Gala",
  //   "imageURL": "https://cdn.rtrcdn.com/assets/imgs/2021111_HPReturn_Icon4_Galas.png",
  //   "slug": "gala"
  // },
  {
    "__typename": "EventType",
    "id": "c21b9e3b-25b2-4249-83c5-301fb990885b",
    "name": "Gece",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/2021111_HPReturn_Icon5_NightsOut.png",
    "slug": "gece"
  },
  {
    "__typename": "EventType",
    "id": "0ad52f0e-e89f-4bd8-9f28-c4a60928ce7e",
    "name": "İş",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/20210913_HPReturn_EventIcon_Work_2.png",
    "slug": "i̇s"
  },
  {
    "__typename": "EventType",
    "id": "c6c47537-2a6c-40dd-b592-65a6b59e36c2",
    "name": "Hafta Sonu",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/20210913_HPReturn_EventIcon_Weekends_2.png",
    "slug": "hafta-sonu"
  },
  {
    "__typename": "EventType",
    "id": "1cd454a8-0020-4ad6-a9b3-fa1db6372583",
    "name": "Tatil",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/2021111_HPReturn_Icon6_Vacations.png",
    "slug": "tatil"
  },
  {
    "__typename": "EventType",
    "id": "c898c409-2cb0-4323-9543-e35974ae4a6a",
    "name": "Nişan",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/2021111_HPReturn_Icon4_Galas.png",
    "slug": "nisan"
  },

  {
    "__typename": "EventType",
    "id": "692ac405-e21a-4677-ab35-9d52f305a25d",
    "name": "Mezuniyet",
    "imageURL": "https://cdn.rtrcdn.com/assets/imgs/101222_Icon_03.jpg",
    "slug": "mezuniyet"
  }
]

const EventType: FC = () => {
  return (
    <>
      <div className="max-w-screen-2xl  mx-auto mt-8">
        <h1 className="font-medium text-4xl text-center">
          Davetini seç
        </h1>
        <div className="flex tablet:flex-nowrap gap-x-4 flex-wrap justify-center gap-y-2  mx-auto pb-8 max-w-2xl w-full ">
          {eventTypeQuery?.map((event) => (
            <Link key={event.id} href={`/collections/${event.slug}`}>
              <a className=" group  rounded-3xl pb-4 px-2 bg-white hover:drop-shadow-lg">
                <div className="flex flex-col  items-center cursor-pointer  justify-start align-center">
                  <div className=" w-24 h-24 tablet:w-28 tablet:h-28  rounded-full">
                    {event.imageURL ? (
                      <Image
                        layout="responsive"
                        width={112}
                        height={112}
                        src={event.imageURL}
                        alt={event.name}
                        priority
                      />
                    ) : null}
                  </div>
                  <span className="mt-1 text-xs text-center font-medium uppercase">
                    {event.name}
                  </span>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default EventType;
